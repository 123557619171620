<template>
  <ValidateForm
    @validated="submit"
    :loading="loading"
    :showFieldObl="mandatory"
  >
    <!-- <NomCorrespondant /> -->
    <br />
    <Textarea
      v-model="accueil_et_contact.INFO_AccueilHorraire"
      label="Horaires d'accueil"
      rules="required"
    ></Textarea>
    <InputTelephone
      v-model="accueil_et_contact.INFO_Telephone"
      rules="required|min:0|max:30"
      label="Téléphone*"
      key="1"
    ></InputTelephone>
    <InputText
      v-model="accueil_et_contact.INFO_Email"
      rules="required|email"
      label="Adresse courriel"
    ></InputText>

    <v-btn
      type="submit"
      :disabled="!hasCodeFederal"
      :loading="loading"
      class="btn btn-primary mt-5"
      >Mettre à jour</v-btn
    >
  </ValidateForm>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidateForm from "@/components/utils/form.vue";
import Textarea from "@/components/utils/textarea.vue";
import InputTelephone from "@/components/utils/inputTelephone.vue";
import InputText from "@/components/utils/input.vue";
// import NomCorrespondant from "@/modules/GestionDeLaStructure/InformationStructure/NomCorrespondant.vue";

export default {
  name: "AccueilEtContact",

  components: {
    ValidateForm,
    InputText,
    Textarea,
    InputTelephone,
    // NomCorrespondant,
  },

  data: () => ({
    loading: false,
    accueil_et_contact: {},
    mandatory: false,
  }),

  async created() {
    await this.setData();
  },

  computed: {
    ...mapGetters("structure", ["hasCodeFederal"]),
  },

  methods: {
    ...mapActions("structure", ["updateAccueilEtContact", "getStructureByID"]),

    async setData() {
      let structure = await this.getStructureByID(this.context.ID_Structure);

      this.accueil_et_contact = {
        INFO_AccueilHorraire: structure.INFO_AccueilHorraire,
        INFO_Telephone: structure.INFO_Telephone,
        INFO_Email: structure.INFO_Email,
      };
    },

    async submit() {
      this.loading = true;

      try {
        await this.updateAccueilEtContact({...this.accueil_et_contact, ID_Structure: this.context.ID_Structure});
        this.loading = false;
        this.$toast.success("Informations modifiées", {
          timeout: 5000,
        });
      } catch (e) {
        this.loading = false;
        this.$toast.error("Oups, une erreur est survenue...", {
          timeout: 5000,
        });
      }
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
